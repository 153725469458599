import React from 'react'
import PropTypes from 'prop-types'

import theme from '../../../styles/theme'

const SvgSmartPhone = ({ color, ...other }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...other}>
            <g fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="2">
                <path d="M16 1h32v62H16zM28 5h8M16 51h32M16 9h32" />
                <circle cx="32" cy="57" r="2" strokeLinejoin="bevel" />
            </g>
        </svg>
    )
}

SvgSmartPhone.defaultProps = {
    color: theme.palette.primary.light,
}

SvgSmartPhone.propTypes = {
    color: PropTypes.string,
}

export default SvgSmartPhone
