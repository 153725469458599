import React from 'react'
import PropTypes from 'prop-types'

import theme from '../../../styles/theme'

const SvgReceipt = ({ color, ...other }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            viewBox="0 0 64 64"
            {...other}
        >
            <g fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="2">
                <path d="M52 62.999v-62H26l-14 14V63l4-2 4 2 4-2 4 2 4-2 4 2 4-2 4 2 4-2z" />
                <path d="M12 14.999h14v-14" />
            </g>
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M38 28s.161-4-6-4-6 3-6 5 .523 4 6 4c6.161 0 7 4.315 7 5 0 1.369.53 6-7 6-6.161 0-6-3.315-6-4M34 21v27M30 21v27"
            />
        </svg>
    )
}

SvgReceipt.defaultProps = {
    color: theme.palette.primary.light,
}

SvgReceipt.propTypes = {
    color: PropTypes.string,
}

export default SvgReceipt
