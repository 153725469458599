import React from 'react'
import PropTypes from 'prop-types'

import theme from '../../../styles/theme'

const SvgCalendar = ({ color, ...other }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...other}>
            <g fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="2">
                <path d="M46 10H18M12 10H1v48h62V10H52" />
                <path d="M12 6h6v8h-6zM46 6h6v8h-6zM10 24h10v10H10zM10 42h10v10H10zM44 24h10v10H44zM44 42h10v10H44zM27 24h10v10H27zM27 42h10v10H27z" />
            </g>
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M1 18h62"
            />
        </svg>
    )
}

SvgCalendar.defaultProps = {
    color: theme.palette.primary.light,
}

SvgCalendar.propTypes = {
    color: PropTypes.string,
}

export default SvgCalendar
