import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { InView } from 'react-intersection-observer'

import { makeStyles } from '@material-ui/styles'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import ContentArticle from '../atoms/ContentArticle'
import { EASE_OUT_QUINT } from '../../constants/global'

const ARROW_TRANSITION_DURATION = '1000ms'
const SVG_ICON_WIDTH = '100px'

const useStyles = makeStyles((theme) => ({
    processItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        marginTop: `${theme.spacing(5)}px`,
        marginBottom: 0,

        '&:first-child': {
            marginTop: `${theme.spacing(10)}px`,
        },

        '&:last-child': {
            paddingBottom: 0,
        },

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            paddingBottom: `${theme.spacing(20)}px`,
        },
    },
    aside: {
        display: 'flex',
        justifyContent: 'center',

        '& > svg': {
            width: SVG_ICON_WIDTH,
            height: 'auto',
        },

        [theme.breakpoints.up('md')]: {
            flex: `1 0 ${SVG_ICON_WIDTH}`,
            flexDirection: 'column',
            justifyContent: 'start',
            marginRight: `${theme.spacing(4)}px`,
        },
    },
    title: {
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
    },
    body: {
        width: '100%',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        backgroundColor: theme.palette.primary.light,

        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
        },
    },
    arrow: {
        position: 'relative',
        display: 'flex',
        alignSelf: 'center',
        width: '2px',
        backgroundColor: `${theme.palette.primary.light}`,
        transform: 'scaleY(0)',
        transition: `transform ${ARROW_TRANSITION_DURATION} ${EASE_OUT_QUINT}`,
        transformOrigin: 'top center',

        '.is-in-view &': {
            transform: 'none',
        },

        '& > svg': {
            display: 'block',
            content: "''",
            position: 'absolute',
            bottom: 1,
            left: '50%',
            transform: 'translate(-50%, 50%)',
            fontSize: 28,
            color: `${theme.palette.primary.light}`,
        },
    },
    arrowMobile: {
        height: '200px',
    },
    arrowDesktop: {
        flexGrow: 1,
        marginTop: `${theme.spacing(3)}px`,
        marginBottom: `-${theme.spacing(20)}px`,
    },
    arrowTrigger: {
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
}))

function ProcessItem({ title, icon, children, isLastItem }) {
    const classes = useStyles()
    const [isInView, setIsInView] = useState(false)

    return (
        <ContentArticle
            className={classnames(
                classes.processItem,
                isInView && 'is-in-view'
            )}
        >
            <aside className={classes.aside}>
                {icon}
                {!isLastItem && (
                    <Hidden smDown>
                        <div
                            className={classnames(
                                classes.arrow,
                                classes.arrowDesktop
                            )}
                        >
                            <KeyboardArrowDownIcon />
                        </div>
                    </Hidden>
                )}
            </aside>
            <section>
                <header className={classes.header}>
                    <Typography className={classes.title} variant="h1">
                        {title}
                    </Typography>
                </header>
                <Typography
                    variant="body1"
                    component={'div'}
                    className={classes.body}
                >
                    {children}
                </Typography>
            </section>
            {!isLastItem && (
                <Hidden mdUp>
                    <div
                        className={classnames(
                            classes.arrow,
                            classes.arrowMobile
                        )}
                    >
                        <KeyboardArrowDownIcon />
                    </div>
                </Hidden>
            )}
            <InView
                triggerOnce
                threshold={1}
                as="div"
                className={classes.arrowTrigger}
                onChange={(inView) => inView && setIsInView(true)}
            />
        </ContentArticle>
    )
}

ProcessItem.defaultProps = {
    isLastItem: false,
}

ProcessItem.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
    children: PropTypes.node,
    isLastItem: PropTypes.bool,
}

export default ProcessItem
