import React from 'react'
import PropTypes from 'prop-types'

import theme from '../../../styles/theme'

const SvgHammer = ({ color, ...other }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...other}>
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M22 20l19 19"
            />
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="1.99998"
                d="M63 55l-5.999 6-19-19 6-6z"
            />
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M1 19L17 3c6 6 13 1 13 1l4 4-22 22L1 19z"
            />
        </svg>
    )
}

SvgHammer.defaultProps = {
    color: theme.palette.primary.light,
}

SvgHammer.propTypes = {
    color: PropTypes.string,
}

export default SvgHammer
