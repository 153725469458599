import React from 'react'
import { graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentSection from '../components/atoms/ContentSection'
import InternalLinkButton from '../components/atoms/InternalLinkButton'
import CtaBanner from '../components/molecules/CtaBanner'
import PageHeader from '../components/organisms/PageHeader'
import ProcessItem from '../components/molecules/ProcessItem'

import SvgHome from '../assets/svg/lineaIcons/SvgHome'
import SvgHammer from '../assets/svg/lineaIcons/SvgHammer'
import SvgReceipt from '../assets/svg/lineaIcons/SvgReceipt'
import SvgCalendar from '../assets/svg/lineaIcons/SvgCalendar'
import SvgSmartPhone from '../assets/svg/lineaIcons/SvgSmartPhone'
import SvgPencilRuler from '../assets/svg/lineaIcons/SvgPencilRuler'
import SvgDocumentCheck from '../assets/svg/lineaIcons/SvgDocumentCheck'

const BuildingProcessPage = (data) => {
    const {
        bannerBlurb,
        headline,
        ctaBanner,
        seoTitle,
        seoDescription,
    } = data.data.contentfulBuildingProcessPage

    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />
            <PageHeader
                headline={headline}
                blurb={bannerBlurb.childMarkdownRemark.html}
            />
            <ContentSection>
                <ProcessItem title={'Contact Us'} icon={<SvgSmartPhone />}>
                    <p>
                        We recommend you get in touch with Andy via the online
                        email form on our website, or by phone as early as
                        possible to discuss your aspirations.
                    </p>
                </ProcessItem>
                <ProcessItem
                    title={'Obligation Free Meeting'}
                    icon={<SvgCalendar />}
                >
                    <p>
                        Next, a one hour catch up at your place. There are a
                        range of important factors that Andy will help you
                        consider such as building consents, site access,
                        geographical aspect, budget and timeframes. Your
                        successful build starts here with careful planning from
                        the outset based on what you're aiming to achieve.
                    </p>
                </ProcessItem>
                <ProcessItem
                    title={'Planning and Design'}
                    icon={<SvgPencilRuler />}
                >
                    <Typography variant="h3">I already have plans</Typography>
                    <p>
                        Great, Fine Lines Construction can flexibly work with
                        these to get your Project Estimate underway.
                    </p>
                    <br />
                    <Divider />
                    <Typography variant="h3">I don't have plans yet</Typography>
                    <p>
                        No problem. To facilitate the planning process for
                        yourself and your designer, we recommend collating mood
                        boards on the free of charge inspirational and
                        resourceful websites such as Houzz, Archipro or
                        Pinterest to help accurately determine and communicate
                        your desired sense of style.
                    </p>
                    <p>
                        You also need to prioritise which components are
                        considered your non- negotiable 'must haves', as well as
                        the quality standard of fixtures and fittings that will
                        suit your needs and your budget best.
                    </p>
                    <Typography variant="h4">The Design Process</Typography>
                    <p>
                        The design process starts with a preliminary meeting to
                        discuss your design brief with your chosen designer.
                        Andy can join you for this meeting and can work with you
                        from the outset to plan for a home that you want and can
                        afford.
                    </p>
                    <p>
                        If you don't already have a designer we can help. Fine
                        Lines Construction has ongoing relationships with a
                        select number of recommended and trusted architects.
                    </p>
                    <p>
                        At Concept Plans stage, a Feasibility Estimate can be
                        arranged to determine if the design will fit within your
                        budget. This is an important and worthwhile process
                        given the investment you are considering. Any required
                        design adjustments can be made, before proceeding with
                        council application plans and specifications. We
                        recommend the designer applies for any necessary council
                        consents on your behalf as they are familiar with this
                        process.
                    </p>
                </ProcessItem>
                <ProcessItem title={'Pricing'} icon={<SvgReceipt />}>
                    <p>
                        With your plans and specifications finalised and council
                        ready, there are two options for how Fine Lines
                        Construction can quote your job - Estimate, or Fixed
                        Price. Andy will discuss with you which may be the best
                        fit based on the scope and complexity of your project.
                    </p>
                    <Typography variant="h3">Estimate</Typography>
                    <p>
                        In most cases, you will get the best value from this
                        option which consists of the Labour and Material Costs,
                        with a fair margin to cover our business running costs.
                    </p>
                    <p>
                        Our estimate can be peer checked by our quantity
                        surveyor prior to hand over with you for appraisal.
                    </p>
                    <p>
                        Our invoices are fully transparent, and you’ll be
                        regularly provided copies of supplies and sub contractor
                        invoices to track spend to ensure the arrangement is
                        fair for all
                    </p>
                    <Typography variant="h3">Fixed Price Contract</Typography>
                    <p>
                        If you feel more comfortable with a Fixed Price
                        Contract, depending on the scale of the project the best
                        process may be to engage our quantity surveyor to
                        produce a Schedule Of Quantities for labour and
                        materials. There is a cost for this service but it will
                        pay for itself.
                    </p>
                    <p>
                        We then use the SOQ to price the job at our cost for our
                        rates to create a fixed price quote. You would own the
                        SOQ and other builders could use it to price their
                        proposals. All the quotes would be for the same amount
                        of labour and materials, saving surprise cost blow outs
                        during construction.
                    </p>
                    <p>
                        During construction the SOQ can be used to order the
                        materials accurately, paying for itself in the time
                        saved by the builder. If we arrange a SOQ for you and go
                        on to win the job, we will deduct the cost of it from
                        the fixed price contract.
                    </p>
                    <Typography variant="h3">Variations</Typography>
                    <p>
                        At times, Contract Variations may be required after the
                        contract has been signed as a result of voluntary
                        changes by the client or unforeseen additional required
                        material and labour cost due to unidentifiable
                        circumstances (for example, rotten framing uncovered
                        during a renovation).
                    </p>
                    <p>
                        All Variations are discussed and prepared with details
                        of additional work and costs. They are agreed upon
                        before additional costs are added.
                    </p>
                </ProcessItem>
                <ProcessItem title={'Agreement'} icon={<SvgDocumentCheck />}>
                    <p>
                        We will provide you with a tailored contract from
                        Certified Builders along with the comprehensive Halo 10
                        Year Residential Guarantee - a unique, independent
                        building insurance cover that's only available to
                        clients who build or renovate with an NZCB approved
                        builder. Critical for protecting your investment.
                    </p>
                    <p>
                        Your contract will detail build time and a payment
                        schedule. Legal review is a good option to consider at
                        this time. Upon contract signing and building deposit
                        paid, you will receive a copy of the Halo 10 year
                        guarantee and Building Schedule. This is when it gets
                        exciting!
                    </p>
                </ProcessItem>
                <ProcessItem title={'Construction'} icon={<SvgHammer />}>
                    <p>
                        When we are engaged to manage your entire project, you
                        are assured a smooth process - from efficiently
                        co-ordinating trades, ordering materials to be delivered
                        on time, booking and attending council inspections and
                        compiling all relevant paperwork to achieve Code of
                        Compliance with minimal stress for you.
                    </p>
                    <p>
                        Fine Lines Construction are a member of HazardCo and we
                        practice workable, effective Health &amp; Safety
                        procedures resulting in a safer working environment for
                        all.
                    </p>
                    <p>
                        Regular meetings on phone and on-site, photos and email
                        updates throughout will keep you informed.
                    </p>
                    <p>
                        Throughout your project, we use Quality Assurance
                        Checklists to ensure all aspects are completed to a high
                        standard for you.
                    </p>
                </ProcessItem>
                <ProcessItem isLastItem title={'Completion'} icon={<SvgHome />}>
                    <p>
                        We attend Final Council Inspection with you to assist
                        you with all aspects required and answer queries raised.
                    </p>
                    <p>
                        When Fine Lines Construction completes a new build or
                        unoccupied renovation for you, we provide a free
                        professional house clean on the areas completed, ready
                        for you to enjoy. T's &amp; C's apply.
                    </p>
                    <p>
                        All relevant product warranties and subcontractor
                        statements will be handed over to you to achieve Code of
                        Compliance. We will also provide a maintenance schedule
                        and a contact list of all subcontractors involved in
                        your project.
                    </p>
                    <p>
                        Fine Lines Construction can assist with CCC application
                        and submission after final inspection. This process will
                        be discussed in the planning stages of your project.
                    </p>
                </ProcessItem>
            </ContentSection>
            {ctaBanner && (
                <CtaBanner
                    title={ctaBanner.bannerTitle}
                    description={ctaBanner.bannerText}
                >
                    {ctaBanner.bannerLinks &&
                        ctaBanner.bannerLinks.map((link) => {
                            return (
                                <InternalLinkButton
                                    key={link.link}
                                    link={link.link}
                                    text={link.label}
                                    variant={link.variant}
                                />
                            )
                        })}
                </CtaBanner>
            )}
        </Layout>
    )
}

export default BuildingProcessPage

export const query = graphql`
    query buildingProcessPageQuery {
        contentfulBuildingProcessPage(slug: { eq: "building-process" }) {
            slug
            title
            headline
            bannerBlurb {
                childMarkdownRemark {
                    html
                }
            }
            ctaBanner {
                bannerText
                bannerTitle
                bannerLinks {
                    label
                    link
                    variant
                }
            }
            seoTitle
            seoDescription
        }
    }
`
