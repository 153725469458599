import React from 'react'
import PropTypes from 'prop-types'

import theme from '../../../styles/theme'

const SvgDocumentCheck = ({ color, ...other }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...other}>
            <g fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="2">
                <path d="M23 1h32v62H9V15z" />
                <path d="M9 15h14V1" />
            </g>
            <path
                fill="none"
                stroke={color}
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M23 36l7 7 13-13"
            />
        </svg>
    )
}

SvgDocumentCheck.defaultProps = {
    color: theme.palette.primary.light,
}

SvgDocumentCheck.propTypes = {
    color: PropTypes.string,
}

export default SvgDocumentCheck
