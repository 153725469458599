import React from 'react'
import PropTypes from 'prop-types'

import theme from '../../../styles/theme'

const SvgPencilRuler = ({ color, ...other }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...other}>
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M1 63V2l61 61z"
            />
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M14 54V30l24 24zM8 22H2M6 30H2M8 38H2M6 46H2M8 54H2M25 1l-6 6 36 36 8 2-2-8zM25 13l6-6"
            />
        </svg>
    )
}

SvgPencilRuler.defaultProps = {
    color: theme.palette.primary.light,
}

SvgPencilRuler.propTypes = {
    color: PropTypes.string,
}

export default SvgPencilRuler
